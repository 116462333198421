/* eslint-disable no-nested-ternary */
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Button from '@/components/atoms/Button';
import Icon from '@/components/atoms/Icon';
import useDeviceType from '@/hooks/useDeviceType';

const Pagination = ({ pagination }) => {
  const { isDesktop } = useDeviceType();
  const router = useRouter();

  const pushToNewPage = (page) => {
    if (page === 0 || page > pagination.total) return;
    router.push(router.asPath.replace(/\/p\/([^&]*)/, `/p/${page}`));
  };

  const renderPages = () => {
    const pagesItems = [];

    let finalPage;

    if (pagination.current >= 3) {
      if (pagination.current + 2 <= pagination.total)
        finalPage = pagination.current + 1;
      else finalPage = pagination.total;
    } else if (!isDesktop && pagination.total >= 3) finalPage = 3;
    else if (isDesktop && pagination.total >= 5) finalPage = 5;
    else finalPage = pagination.total;

    const pages = {
      init: pagination.current === 1 ? 1 : pagination.current - 1,
      final: finalPage
    };

    // eslint-disable-next-line no-plusplus
    for (let i = pages.init; i <= pages.final; i++) {
      pagesItems.push(
        <li key={i}>
          <Button
            border={`${i === pagination.current ? 'var(--color-primary)' : ''}`}
            onClick={() => pushToNewPage(i)}
            br="soft"
            style={{ cursor: 'pointer' }}
            textColor={
              i === pagination.current ? 'var(--color-primary)' : 'white'
            }
            label={`${i}`}
          />
        </li>
      );
    }
    return pagesItems;
  };
  return (
    <div className={styles.container}>
      <ul className={styles.pages}>
        <li className={`${styles.page} ${styles.chevron}`}>
          <Button
            onClick={() => pushToNewPage(pagination.current - 1)}
            withOutPadding
            variant={`${
              pagination.current <= 1 ? 'transparent-disabled' : 'transparent'
            }`}
            startIcon={
              <Icon
                type="arrow"
                width="13px"
                reverse
                color={`${
                  pagination.current <= 1
                    ? 'var(--color-text-on-transparent-disabled)'
                    : 'var(--color-text-on-transparent)'
                }`}
              />
            }
          />
        </li>
        {renderPages()}

        <li className={`${styles.page} ${styles.chevron}`}>
          <Button
            onClick={() => pushToNewPage(pagination.current + 1)}
            withOutPadding
            variant={`${
              pagination.current >= pagination.total
                ? 'transparent-disabled'
                : 'transparent'
            }`}
            startIcon={
              <Icon
                type="arrow"
                width="13px"
                color={`${
                  pagination.current >= pagination.total
                    ? 'var(--color-text-on-transparent-disabled)'
                    : 'var(--color-text-on-transparent)'
                }`}
              />
            }
          />
        </li>
      </ul>
    </div>
  );
};
Pagination.propTypes = {
  pagination: PropTypes.object.isRequired
};
export default Pagination;
